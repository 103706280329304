<template>
    <div class="con">
        <div class="l">
            <div class="top">
                <div class="title">咨询店铺</div>
                <div class="num">({{ messageListTatol }})</div>
            </div>
            <div class="sonS">
                <div class="son cur" :class="[i.act ? 'actClass' : '']" @click="messageClick(i, index)"
                    v-for="(i, index) in messageListData" :key="index">
                    <div class="img">
                        <img :src="i.image" alt="">
                    </div>
                    <div class="info">
                        <div class="to">
                            <div class="tit">{{ i.name }}</div>
                            <div class="time">09:20</div>
                        </div>
                        <div class="value">{{ i.msg }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="z">
            <div class="top" @click="hetigBto">
                <div class="title">深圳市创世纪机械有限公司</div>
                <div class="guanz" v-if="isFans == false" @click="followSubscription">
                    <img src="@/assets/icon/wgzdp.png" alt="">
                    <div class="text">关注店铺</div>
                </div>
                <div class="yguanz" v-else @click="followSubscription">
                    <img src="@/assets/icon/ygzdp.png" alt="">
                    <div class="text">已关注</div>
                </div>
            </div>
            <div class="cooo" id="myDiv">
                <!-- 咨询商品 -->
                <div class="zxComm" v-if="zxShow">
                    <div class="xx"><i class="el-icon-close cur" @click="zxShowOffClick"></i></div>
                    <div class="concent">
                        <div class="l">
                            <img :src="messageCommDataAct.img.split(',')[0]" alt="">
                            <div class="teart">
                                <div class="title">
                                    {{ messageCommDataAct.title }}
                                </div>
                                <div class="jeBox">
                                    <div class="pr">¥{{ messageCommDataAct.price }}</div>
                                    <div class="xl">销量：{{ messageCommDataAct.sales }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="r cur">咨询这件商品</div>
                    </div>
                </div>
                <!-- 咨询订单 -->
                <div class="zxComm" style="max-height: 208px;overflow-y: auto;padding: 10px 20px;" v-if="ddShow">
                    <div class="xx"><i class="el-icon-close cur" @click="ddShowOffClick"></i></div>
                    <div class="concent">
                        <div class="l" v-for="(i, inde) in messageOrderDataAct.listOrderItem" :key="inde">
                            <img :src="i.img.split(',')[0]" alt="">
                            <div class="teart">
                                <div class="title">
                                    {{ i.productName }}
                                </div>
                                <div class="jeBox">
                                    <div class="pr">¥{{ i.paymentMoney || i.price }}</div>
                                    <div class="xl">数量：{{ i.pnum }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="orderInfo">
                            <div>
                                <div class="sonText">
                                    <div class="lTe">创建时间：</div>
                                    <div class="rTe">{{ messageOrderDataAct.createTime }}</div>
                                </div>
                                <div class="sonText">
                                    <div class="lTe">订单编号：</div>
                                    <div class="rTe">{{ messageOrderDataAct.oid }}</div>
                                </div>
                            </div>
                            <div class="r cur">咨询这个订单</div>
                        </div>
                    </div>
                </div>

                <!-- 商家对话 -->
                <div class="storeSon" v-for="(i, index) in valueArr" :key="index">
                    <div class="img">
                        <img src="@/assets/mrtx.png" alt="">
                    </div>
                    <div class="dHua" style="background-color: #CDE3FF;">
                        <div class="text" v-if="i.msg.includes(`https://`)">
                            <img :src="i.msg" style="width: 100%;object-fit: cover;" alt="">
                        </div>
                        <div class="text" v-else>{{ i.msg }}</div>
                    </div>

                </div>

            </div>
            <div class="bot">
                <div class="gn">
                    <div class="son cur">
                        <i class="el-icon-picture-outline"></i>
                        <div class="text">发送图片</div>
                    </div>
                </div>
                <div class="inpt">
                    <el-input type="textarea" resize="none" :autosize="{ minRows: 3, maxRows: 3 }" placeholder="请输入内容"
                        v-model="textarea">
                    </el-input>
                </div>
                <div class="faS" @click="faSong">发送</div>
            </div>


        </div>
        <div class="r">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部商品" name="first">
                    <div class="sonS">
                        <div class="son cur" :class="[i.act ? 'actClass' : '']" @click="zxShowClick(i, index)"
                            v-for="(i, index) in messageCommListData" :key="index">
                            <div class="img">
                                <img :src="i.img.split(',')[0]" alt="">
                            </div>
                            <div class="info">
                                <div class="tit">{{ i.title }}</div>
                                <div class="jiag">
                                    <div class="pr">¥{{ i.price }}</div>
                                    <div class="xiaol">销量：{{ i.sales }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="店铺订单" name="second">
                    <div class="sonDD">
                        <div class="son cur" :class="[i.act ? 'actClass' : '']" @click="ddShowClick(i, index)"
                            v-for="(i, index) in messageOrderListData" :key="index">
                            <div class="head" v-for="(item, ind) in i.listOrderItem">
                                <div class="img">
                                    <img :src="item.img.split(',')[0]" alt="">
                                </div>
                                <div class="info">
                                    <div class="tit">{{ item.productName }}</div>
                                    <div class="jiag">
                                        <div class="pr">¥{{ item.paymentMoney || item.price }}</div>
                                        <div class="xiaol">数量：{{ item.pnum }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text">
                                <div class="te">创建时间：</div>
                                <div class="time">{{ i.createTime }}</div>
                            </div>
                            <div class="text">
                                <div class="te">订单编号：</div>
                                <div class="time">{{ i.oid }}</div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { getMessageList, getMessageComm, getMessageOrder } from "@/utils/api/myApi/index"
export default {
    // props: ['total', 'pageSize','currentPage'],
    data() {
        return {
            activeName: 'first',
            // 是否关注店铺
            isFans: false,
            // 输入框
            textarea: '',
            // 咨询商品弹窗
            zxShow: false,
            // 订单弹窗
            ddShow: false,
            // 左侧聊天列表
            messageListData: [],
            messageListTatol: 0,
            // 右侧商品
            messageCommListData: [],
            // 选中商品
            messageCommDataAct: {},
            // 右侧订单
            messageOrderListData: {},
            // 选中订单
            messageOrderDataAct: {},
            // 聊天内容
            valueArr: []
        }
    },
    created() {
        // this.$socket.addEventListener('message', this.onMessage);
        // 关闭WebSocket连接
        // this.$socket.close();
    },
    mounted() {

        // 获取聊天列表
        this.messageList()
        // 获取商品
        this.messageComm()
        // 获取订单
        this.messageOrder()
    },
    watch: {
        '$store.state.isFans'(newVal, oldVal) {
            this.isFans = textarea
        },
        '$store.state.socketValueArr'(newVal, oldVal) {
            this.valueArr.push(newVal)
            setTimeout(() => {
                // 在这里写入你想要延迟执行的操作
                this.hetigBto()
            }, 500);
        },
    },
    methods: {

        onMessage(event) {
            const message = event.data;
            console.log('Received message:接收到消息==》', JSON.parse(message));
            let newMessObj = JSON.parse(message)
            if (!newMessObj.mt) return
            if (newMessObj.mt == 500) {

            } else {
                this.valueArr.push(newMessObj)
            }
        },
        faSong(){
            this.$store.commit('socketSendValueClick',{
                msg:this.value
            })
        },

        // 获取左侧聊天列表
        async messageList() {
            const res = await getMessageList({
                size: 99,
                page: 1
            })
            if (res.statusCode == 8201) {
                this.messageListData = res.data.records
                this.messageListTatol = res.data.total
                this.messageListData.map(n => {
                    this.$set(n, 'act', false)
                })
                this.messageListData.map(n => n.act = false)
                this.messageListData[0].act = true
            } else {
                this.$message.error("网络异常")
            }
        },
        // 选择聊天窗口
        messageClick(i, index) {
            console.log(i, '选择聊天窗口'),
                this.messageListData.map(n => n.act = false)
            this.messageListData[index].act = true
        },
        // 获取商品列表
        async messageComm() {
            const res = await getMessageComm({
                title: '',
                type: 'ALL',
                storeCategoryId: '',
                storeId: '396',
                page: 1,
                size: 33
            })
            if (res.statusCode == 8201) {
                this.messageCommListData = res.data.records
                this.messageCommListData.map(n => {
                    this.$set(n, 'act', false)
                })
                // this.messageCommListData.map(n => n.act = false)
                // this.messageCommListData[0].act = true
            } else {
                this.$message.error("网络异常")
            }
        },
        // 选中商品弹窗
        zxShowClick(i, index) {
            this.ddShowOffClick()
            this.messageCommDataAct = i
            this.messageCommListData.map(n => n.act = false)
            this.messageCommListData[index].act = true
            this.zxShow = true
        },
        // 关闭商品弹窗
        zxShowOffClick() {
            this.zxShow = false
            this.messageCommDataAct = {}
            this.messageCommListData.map(n => n.act = false)
        },
        // 获取订单列表
        async messageOrder() {
            const res = await getMessageOrder({
                title: '',
                type: 'ALL',
                storeCategoryId: '',
                storeId: '396',
                page: 1,
                size: 22
            })
            if (res.statusCode == 8201) {
                this.messageOrderListData = res.data.records
                this.messageOrderListData.map(n => {
                    this.$set(n, 'act', false)
                })
                // this.messageOrderListData.map(n => n.act = false)
                // this.messageOrderListData[0].act = true
            } else {
                this.$message.error("网络异常")
            }
        },
        // 选中订单窗口
        ddShowClick(i, index) {
            this.zxShowOffClick()
            this.messageOrderDataAct = i
            this.messageOrderListData.map(n => n.act = false)
            this.messageOrderListData[index].act = true
            this.ddShow = true
        },
        // 关闭订单窗口
        ddShowOffClick() {
            this.ddShow = false
            this.messageOrderDataAct = {}
            this.messageOrderListData.map(n => n.act = false)
        },
        // 聊天窗口滚动到最底下
        hetigBto() {
            // 获取 div 元素
            var myDiv = document.getElementById('myDiv');
            // 将滚动条滚动到最下面
            myDiv.scrollTop = myDiv.scrollHeight;
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        followSubscription() {
            this.isFans = !this.isFans
        }
    }
}
</script>

<style lang="less" scoped>
.actClass {
    background-color: #f5f5f5;
}

.con {
    width: 1200px;
    height: 730px;
    background: #F5F5F5;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #DCDCDC;
    margin: 50px auto;
    display: flex;
    overflow: hidden;

    >.l {
        width: 300px;
        height: 100%;
        border-right: 1px solid #e0e0e0;
        background-color: #fff;


        >.top {
            width: 100%;
            height: 50px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            padding-left: 20px;
            background: linear-gradient(180deg, #FFFFFF 0%, #EEF6FF 100%);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            border-radius: 8px 0px 0px 0px;
            opacity: 1;
            box-sizing: border-box;

            >.title {
                margin-right: 5px;
            }
        }

        .sonS {
            width: 100%;
            height: calc(100% - 50px);
            overflow: auto;

            >.son {
                width: 100%;
                height: 68px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #efefef;

                >.img {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    border: 1px solid #E0E0E0;
                    overflow: hidden;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >img {
                        width: 100%;
                        object-fit: cover;
                    }
                }

                >.info {
                    width: 220px;
                    height: 48px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: space-around;

                    >.to {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        >.tit {
                            width: 180px;
                            font-size: 14px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        >.time {
                            font-size: 12px;
                            color: #999;
                        }
                    }

                    >.value {
                        width: 200px;
                        font-size: 12px;
                        color: #666666;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    >.r {
        width: 320px;
        height: 100%;
        border-left: 1px solid #e0e0e0;
        background-color: #fff;

        .sonS {
            width: 100%;
            height: 680px;
            overflow-y: auto;


            >.son {
                width: 100%;
                min-height: 68px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #efefef;

                >.img {
                    width: 64px;
                    height: 64px;
                    overflow: hidden;
                    margin-right: 10px;

                    >img {
                        width: 100%;
                        object-fit: cover;
                    }
                }

                >.info {
                    width: 230px;
                    height: 64px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    >.tit {
                        width: 100%;
                        font-size: 12px;
                        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    >.jiag {
                        width: 100%;
                        display: flex;
                        align-items: center;

                        >.pr {
                            font-size: 14px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            color: #FF4242;
                            margin-right: 10px;
                        }

                        >.xiaol {
                            font-size: 12px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                }
            }
        }

        .sonDD {
            width: 100%;
            height: 665px;
            overflow-y: auto;

            >.son {
                width: 100%;
                min-height: 130px;
                border-bottom: 1px solid #efefef;

                >.head {
                    width: 100%;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;

                    >.img {
                        width: 64px;
                        height: 64px;
                        overflow: hidden;
                        margin-right: 10px;

                        >img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    >.info {
                        width: 230px;
                        height: 64px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        >.tit {
                            width: 100%;
                            font-size: 12px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        >.jiag {
                            width: 100%;
                            display: flex;
                            align-items: center;

                            >.pr {
                                font-size: 14px;
                                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                font-weight: 400;
                                color: #FF4242;
                                margin-right: 10px;
                            }

                            >.xiaol {
                                font-size: 12px;
                                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                }

                >.text {
                    font-size: 12px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    width: 100%;
                    align-items: center;
                    display: flex;
                    margin-bottom: 5px;
                    padding-left: 15px;
                    box-sizing: border-box;

                    >.te {
                        width: 60px;
                    }
                }
            }
        }
    }

    >.z {
        width: 580px;
        height: 100%;
        position: relative;


        >.top {
            width: 100%;
            height: 50px;
            font-size: 20px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            padding-left: 20px;
            background: linear-gradient(180deg, #FFFFFF 0%, #EEF6FF 100%);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            opacity: 1;
            box-sizing: border-box;

            >.title {
                margin-right: 15px;
            }

            >.guanz {
                cursor: pointer;
                width: 85px;
                height: 24px;
                padding: 1px 8px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid #FF7D7D;
                background-color: #FF4242;
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #fff;

                >img {
                    width: 13px;
                    margin-right: 3px;
                }
            }

            >.yguanz {
                cursor: pointer;
                width: 85px;
                height: 24px;
                padding: 1px 8px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid #FF7D7D;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #FF4242;

                >img {
                    width: 13px;
                    margin-right: 3px;
                }
            }
        }

        >.cooo {
            width: 580px;
            height: calc(100% - 210px);
            padding: 20px;
            box-sizing: border-box;
            overflow: auto;
            // position: relative;

            >.zxComm {
                width: 520px;
                height: auto;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
                border-radius: 8px 8px 8px 8px;
                opacity: 1;
                border: 1px solid #E0E0E0;
                position: absolute;
                top: 70px;
                left: 50%;
                transform: translateX(-50%);
                padding: 10px;
                box-sizing: border-box;

                >.xx {
                    width: 100%;
                    text-align: right;
                    color: #666;
                }

                >.concent {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: -10px;

                    >.l {
                        width: calc(100% - 150px);
                        height: 100%;
                        display: flex;
                        align-items: center;

                        >img {
                            width: 64px;
                            height: 64px;
                            object-fit: cover;
                            margin-right: 20px;
                        }

                        >.teart {
                            width: 230px;
                            height: 60px;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            justify-content: space-between;

                            >.title {
                                font-size: 12px;
                                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }

                            >.jeBox {
                                display: flex;
                                align-items: center;

                                >.pr {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                    font-weight: 400;
                                    color: #FF4242;
                                    margin-right: 10px;
                                }

                                >.xl {
                                    font-size: 12px;
                                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                }
                            }
                        }
                    }

                    >.orderInfo {
                        width: 100%;
                        height: 40px;
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .sonText {
                            font-size: 12px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            display: flex;
                            align-items: center;
                            margin-bottom: 5px;

                            >.lTe {
                                margin-right: 5px;
                            }
                        }
                    }

                    .r {
                        width: 90px;
                        height: 32px;
                        background: linear-gradient(270deg, #4690FF 0%, #2772E1 100%);
                        border-radius: 40px 40px 40px 40px;
                        font-size: 12px;
                        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 32px;
                    }
                }
            }

            >.storeSon {
                max-width: 420px;
                min-width: 150px;
                min-height: 48px;
                display: flex;
                margin-bottom: 20px;

                >.img {
                    width: 58px;
                    height: 58px;
                    overflow: hidden;
                    margin-right: 10px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >img {
                        width: 100%;
                        object-fit: cover;
                    }
                }

                >.dHua {
                    max-width: 360px;
                    min-width: 90px;
                    min-height: 48px;
                    // background: url('../assets/sjdhk.png') no-repeat;
                    // background-size: 100% 100%;
                    background-color: #fff;
                    border-radius: 8px;
                    // padding: 10px 15px 10px 35px;
                    padding: 10px;
                    box-sizing: border-box;

                    >.text {
                        font-size: 14px;
                        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                    }

                    >.son {
                        width: 310px;
                        height: 130px;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        justify-content: center;
                        border-radius: 8px;
                        border: 1px solid #e0e0e0;
                        box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.05);
                        background-color: #f4f4f4;

                        >.head {
                            width: 100%;
                            height: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            >.img {
                                width: 64px;
                                height: 64px;
                                overflow: hidden;
                                margin-right: 10px;

                                >img {
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }

                            >.info {
                                width: 210px;
                                height: 64px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;

                                >.tit {
                                    width: 100%;
                                    font-size: 12px;
                                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                >.jiag {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;

                                    >.pr {
                                        font-size: 14px;
                                        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                        font-weight: 400;
                                        color: #FF4242;
                                        margin-right: 10px;
                                    }

                                    >.xiaol {
                                        font-size: 12px;
                                        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                    }
                                }
                            }
                        }

                        >.text {
                            font-size: 12px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            width: 100%;
                            align-items: center;
                            display: flex;
                            margin-bottom: 5px;
                            padding-left: 15px;
                            box-sizing: border-box;

                            >.te {
                                width: 60px;
                            }
                        }
                    }
                }
            }
        }

        >.bot {
            width: 100%;
            height: 200px;
            background-color: #fff;
            padding: 10px 20px;
            box-sizing: border-box;

            >.gn {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                >.son {
                    width: auto;
                    display: flex;
                    align-items: center;

                    >.text {
                        font-size: 12px;
                        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        margin-left: 5px;
                    }
                }
            }

            >.faS {
                width: 60px;
                height: 24px;
                background: #2C76E5;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                font-family: Microsoft YaHei-Light, Microsoft YaHei;
                font-weight: 300;
                color: #FFFFFF;
                text-align: center;
                line-height: 24px;
                margin-top: 10px;
                float: right;
            }
        }
    }
}

/deep/ .el-tabs__nav-wrap::after {
    background-color: rgba(0, 0, 0, 0) !important;
}

/deep/ .el-tabs__header {
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #FFFFFF 0%, #EEF6FF 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0px 8px 0px 0px;
    opacity: 1;
    box-sizing: border-box;
    height: 50px;
    margin: 0;
}


/* 设置滚动条的样式 */
::-webkit-scrollbar {
    width: 4px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #d9d9d9;
}
</style>